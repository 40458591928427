import Board from './board';
/* eslint no-param-reassign: "off", no-use-before-define: "off" */

export default class Tetromino {
    constructor(numColumns) {
        this.numColumns = numColumns;
        this.numFeatures = 8;
        this.fillColor = 2; // to make the placed tetrominos "red"
        this.tetrominoNames = [
            0, // "straight"
            1, // "square"
            2, // "snaker"
            3, // "snakel"
            4, // "t"
            5, // "rcorner"
            6 // "lcorner"
        ];
        this.numTetrominos = this.tetrominoNames.length;
        this.currentTetrominoIndex = 0;
        this.nextTetromino();
    }

    nextTetromino() {
        this.currentTetrominoIndex = Math.floor(Math.random() * this.numTetrominos);
    }

    getAfterStates(currentBoard) {
        const afterStates = [];
        if (this.currentTetrominoIndex === 0) {
            // Straight
            // 1
            // 1
            // 1
            // 1
            for (let colIx = 0; colIx < currentBoard.numColumns; colIx += 1) {
                const anchorRow = currentBoard.lfr[colIx];
                const newLfr = currentBoard.lfr.slice(0);
                newLfr[colIx] += 4;
                const newBoardBoard = currentBoard.board.map(arr => arr.slice(0));
                let hasOverlappingFields = false;
                if (anchorRow + 4 > currentBoard.numRows) {
                    for (let extraRow = 0; extraRow < 4; extraRow += 1) {
                        newBoardBoard.push(Array(this.numColumns).fill(0));
                    }
                    hasOverlappingFields = true;
                }
                for (let rowIx = anchorRow; rowIx < anchorRow + 4; rowIx += 1) {
                    newBoardBoard[rowIx][colIx] = this.fillColor;
                }
                const newBoard = new Board(
                    newBoardBoard,
                    newLfr,
                    Array.from({ length: 4 }, (x, i) => i + anchorRow),
                    [1, 1, 1, 1],
                    1.5,
                    this.numFeatures,
                    false,
                    hasOverlappingFields,
                    colIx,
                    1
                );
                if (!newBoard.isTerminalState) {
                    afterStates.push(newBoard);
                }

                // Horizontal placement 
                // [1, 1, 1, 1]
                if (colIx < this.numColumns - 3) {
                    const anchorRow = Math.max(...currentBoard.lfr.slice(colIx, colIx + 4));
                    const anchPlusOne = anchorRow + 1;
                    const newLfr = currentBoard.lfr.slice(0);
                    newLfr.splice(colIx, 4, anchPlusOne, anchPlusOne, anchPlusOne, anchPlusOne);
                    const newBoardBoard = currentBoard.board.map(arr => arr.slice(0));
                    let hasOverlappingFields = false;
                    if (anchorRow + 1 > currentBoard.numRows) {
                        for (let extraRow = 0; extraRow < 4; extraRow += 1) {
                            newBoardBoard.push(Array(this.numColumns).fill(0));
                        }
                        hasOverlappingFields = true;
                    }
                    for (let colIxIx = colIx; colIxIx < colIx + 4; colIxIx += 1) {
                        newBoardBoard[anchorRow][colIxIx] = this.fillColor;
                    }
                    const newBoard = new Board(
                        newBoardBoard,
                        newLfr,
                        [anchorRow],
                        [4],
                        0,
                        this.numFeatures,
                        false,
                        hasOverlappingFields,
                        colIx,
                        0
                    );
                    if (!newBoard.isTerminalState) {
                        afterStates.push(newBoard);
                    }
                }
            }
        } else if (this.currentTetrominoIndex === 1) {
            // Square
            // 1 1
            // 1 1
            for (let colIx = 0; colIx < currentBoard.numColumns - 1; colIx += 1) {
                let anchorRow = Math.max(...currentBoard.lfr.slice(colIx, colIx + 2));
                let newLfr = currentBoard.lfr.slice(0);
                newLfr[colIx] += 4;
                newLfr.splice(colIx, 2, anchorRow + 2, anchorRow + 2);
                const newBoardBoard = currentBoard.board.map(arr => arr.slice(0));
                let hasOverlappingFields = false;
                if (anchorRow + 2 > currentBoard.numRows) {
                    for (let extraRow = 0; extraRow < 4; extraRow += 1) {
                        newBoardBoard.push(Array(this.numColumns).fill(0));
                    }
                    hasOverlappingFields = true;
                }
                for (let rowIx = anchorRow; rowIx < anchorRow + 2; rowIx += 1) {
                    for (let colIxIx = colIx; colIxIx < colIx + 2; colIxIx += 1) {
                        newBoardBoard[rowIx][colIxIx] = this.fillColor;
                    }
                }
                const newBoard = new Board(
                    newBoardBoard,
                    newLfr,
                    Array.from({ length: 2 }, (x, i) => i + anchorRow),
                    [2, 2],
                    0.5,
                    this.numFeatures,
                    false,
                    hasOverlappingFields,
                    colIx,
                    0
                );
                if (!newBoard.isTerminalState) {
                    afterStates.push(newBoard);
                }
            }
        } else if (this.currentTetrominoIndex === 2) {
            // Snake R
            // vertical placements
            // 1 0
            // 1 1
            // 0 1
            for (let colIx = 0; colIx < currentBoard.numColumns - 1; colIx += 1) {
                let newLfr = currentBoard.lfr.slice(0);
                let anchorRow = Math.max(newLfr[colIx] - 1, newLfr[colIx + 1]);
                newLfr[colIx] = anchorRow + 3;
                newLfr[colIx + 1] = anchorRow + 2;
                const newBoardBoard = currentBoard.board.map(arr => arr.slice(0));
                let hasOverlappingFields = false;
                if (anchorRow + 3 > currentBoard.numRows) {
                    for (let extraRow = 0; extraRow < 4; extraRow += 1) {
                        newBoardBoard.push(Array(this.numColumns).fill(0));
                    }
                    hasOverlappingFields = true;
                }
                newBoardBoard[anchorRow][colIx + 1] = this.fillColor;
                newBoardBoard[anchorRow + 1][colIx] = this.fillColor;
                newBoardBoard[anchorRow + 1][colIx + 1] = this.fillColor;
                newBoardBoard[anchorRow + 2][colIx] = this.fillColor;
                const newBoard = new Board(
                    newBoardBoard,
                    newLfr,
                    Array.from({ length: 2 }, (x, i) => i + anchorRow),
                    [1, 2],
                    1,
                    this.numFeatures,
                    false,
                    hasOverlappingFields,
                    colIx,
                    1
                );
                if (!newBoard.isTerminalState) {
                    afterStates.push(newBoard);
                }

                // Horizontal placements
                // 0 1 1
                // 1 1 0
                if (colIx < currentBoard.numColumns - 2) {
                    const newLfr = currentBoard.lfr.slice(0);
                    const anchorRow = Math.max(
                        newLfr[colIx],
                        newLfr[colIx + 1],
                        newLfr[colIx + 2] - 1
                    );
                    newLfr[colIx] = anchorRow + 1;
                    newLfr[colIx + 1] = anchorRow + 2;
                    newLfr[colIx + 2] = anchorRow + 2;
                    const newBoardBoard = currentBoard.board.map(arr => arr.slice(0));
                    let hasOverlappingFields = false;
                    if (anchorRow + 2 > currentBoard.numRows) {
                        for (let extraRow = 0; extraRow < 4; extraRow += 1) {
                            newBoardBoard.push(Array(this.numColumns).fill(0));
                        }
                        hasOverlappingFields = true;
                    }
                    newBoardBoard[anchorRow][colIx] = this.fillColor;
                    newBoardBoard[anchorRow][colIx + 1] = this.fillColor;
                    newBoardBoard[anchorRow + 1][colIx + 1] = this.fillColor;
                    newBoardBoard[anchorRow + 1][colIx + 2] = this.fillColor;
                    const newBoard = new Board(
                        newBoardBoard,
                        newLfr,
                        Array.from({ length: 2 }, (x, i) => i + anchorRow),
                        [1, 2],
                        1,
                        this.numFeatures,
                        false,
                        hasOverlappingFields,
                        colIx,
                        0
                    );
                    if (!newBoard.isTerminalState) {
                        afterStates.push(newBoard);
                    }
                }
            }
        } else if (this.currentTetrominoIndex === 3) {
            // Snake L
            // vertical placements
            // 0 1
            // 1 1
            // 1 0
            for (let colIx = 0; colIx < currentBoard.numColumns - 1; colIx += 1) {
                let newLfr = currentBoard.lfr.slice(0);
                let anchorRow = Math.max(newLfr[colIx], newLfr[colIx + 1] - 1);
                newLfr[colIx] = anchorRow + 2;
                newLfr[colIx + 1] = anchorRow + 3;
                const newBoardBoard = currentBoard.board.map(arr => arr.slice(0));
                let hasOverlappingFields = false;
                if (anchorRow + 3 > currentBoard.numRows) {
                    for (let extraRow = 0; extraRow < 4; extraRow += 1) {
                        newBoardBoard.push(Array(this.numColumns).fill(0));
                    }
                    hasOverlappingFields = true;
                }
                newBoardBoard[anchorRow][colIx] = this.fillColor;
                newBoardBoard[anchorRow + 1][colIx] = this.fillColor;
                newBoardBoard[anchorRow + 1][colIx + 1] = this.fillColor;
                newBoardBoard[anchorRow + 2][colIx + 1] = this.fillColor;
                const newBoard = new Board(
                    newBoardBoard,
                    newLfr,
                    Array.from({ length: 2 }, (x, i) => i + anchorRow),
                    [1, 2],
                    1,
                    this.numFeatures,
                    false,
                    hasOverlappingFields,
                    colIx,
                    1
                );
                if (!newBoard.isTerminalState) {
                    afterStates.push(newBoard);
                }

                // Horizontal placements
                // 1 1 0
                // 0 1 1
                if (colIx < currentBoard.numColumns - 2) {
                    let newLfr = currentBoard.lfr.slice(0);
                    let anchorRow = Math.max(
                        newLfr[colIx] - 1,
                        newLfr[colIx + 1],
                        newLfr[colIx + 2]
                    );
                    newLfr[colIx] = anchorRow + 2;
                    newLfr[colIx + 1] = anchorRow + 2;
                    newLfr[colIx + 2] = anchorRow + 1;
                    const newBoardBoard = currentBoard.board.map(arr => arr.slice(0));
                    let hasOverlappingFields = false;
                    if (anchorRow + 2 > currentBoard.numRows) {
                        for (let extraRow = 0; extraRow < 4; extraRow += 1) {
                            newBoardBoard.push(Array(this.numColumns).fill(0));
                        }
                        hasOverlappingFields = true;
                    }
                    newBoardBoard[anchorRow][colIx + 1] = this.fillColor;
                    newBoardBoard[anchorRow][colIx + 2] = this.fillColor;
                    newBoardBoard[anchorRow + 1][colIx] = this.fillColor;
                    newBoardBoard[anchorRow + 1][colIx + 1] = this.fillColor;
                    const newBoard = new Board(
                        newBoardBoard,
                        newLfr,
                        [anchorRow],
                        [2],
                        0.5,
                        this.numFeatures,
                        false,
                        hasOverlappingFields,
                        colIx,
                        0
                    );
                    if (!newBoard.isTerminalState) {
                        afterStates.push(newBoard);
                    }
                }
            }
        } else if (this.currentTetrominoIndex === 4) {
            // T
            // vertical placements
            for (let colIx = 0; colIx < currentBoard.numColumns - 1; colIx += 1) {
                // single cell on left
                // 0 1
                // 1 1
                // 0 1
                let newLfr = currentBoard.lfr.slice(0);
                let anchorRow = Math.max(newLfr[colIx] - 1, newLfr[colIx + 1]);
                newLfr[colIx] = anchorRow + 2;
                newLfr[colIx + 1] = anchorRow + 3;
                let newBoardBoard = currentBoard.board.map(arr => arr.slice(0));
                let hasOverlappingFields = false;
                if (anchorRow + 3 > currentBoard.numRows) {
                    for (let extraRow = 0; extraRow < 4; extraRow += 1) {
                        newBoardBoard.push(Array(this.numColumns).fill(0));
                    }
                    hasOverlappingFields = true;
                }
                newBoardBoard[anchorRow][colIx + 1] = this.fillColor;
                newBoardBoard[anchorRow + 1][colIx] = this.fillColor;
                newBoardBoard[anchorRow + 1][colIx + 1] = this.fillColor;
                newBoardBoard[anchorRow + 2][colIx + 1] = this.fillColor;
                let newBoard = new Board(
                    newBoardBoard,
                    newLfr,
                    Array.from({ length: 2 }, (x, i) => i + anchorRow),
                    [1, 2],
                    1,
                    this.numFeatures,
                    false,
                    hasOverlappingFields,
                    colIx,
                    3
                );
                if (!newBoard.isTerminalState) {
                    afterStates.push(newBoard);
                }

                // Single cell on right
                // 1 0 
                // 1 1
                // 1 0
                newLfr = currentBoard.lfr.slice(0);
                anchorRow = Math.max(newLfr[colIx], newLfr[colIx + 1] - 1);
                newLfr[colIx] = anchorRow + 3;
                newLfr[colIx + 1] = anchorRow + 2;
                newBoardBoard = currentBoard.board.map(arr => arr.slice(0));
                hasOverlappingFields = false;
                if (anchorRow + 3 > currentBoard.numRows) {
                    for (let extraRow = 0; extraRow < 4; extraRow += 1) {
                        newBoardBoard.push(Array(this.numColumns).fill(0));
                    }
                    hasOverlappingFields = true;
                }
                newBoardBoard[anchorRow][colIx] = this.fillColor;
                newBoardBoard[anchorRow + 1][colIx] = this.fillColor;
                newBoardBoard[anchorRow + 1][colIx + 1] = this.fillColor;
                newBoardBoard[anchorRow + 2][colIx] = this.fillColor;
                newBoard = new Board(
                    newBoardBoard,
                    newLfr,
                    Array.from({ length: 2 }, (x, i) => i + anchorRow),
                    [1, 2],
                    1,
                    this.numFeatures,
                    false,
                    hasOverlappingFields,
                    colIx,
                    1
                );
                if (!newBoard.isTerminalState) {
                    afterStates.push(newBoard);
                }

                if (colIx < this.numColumns - 2) {
                    // Upside-down T
                    // 0 1 0
                    // 1 1 1

                    let newLfr = currentBoard.lfr.slice(0);
                    let anchorRow = Math.max(newLfr[colIx], newLfr[colIx + 1], newLfr[colIx + 2]);
                    newLfr[colIx] = anchorRow + 1;
                    newLfr[colIx + 1] = anchorRow + 2;
                    newLfr[colIx + 2] = anchorRow + 1;
                    let newBoardBoard = currentBoard.board.map(arr => arr.slice(0));
                    let hasOverlappingFields = false;
                    if (anchorRow + 2 > currentBoard.numRows) {
                        for (let extraRow = 0; extraRow < 4; extraRow += 1) {
                            newBoardBoard.push(Array(this.numColumns).fill(0));
                        }
                        hasOverlappingFields = true;
                    }
                    newBoardBoard[anchorRow][colIx] = this.fillColor;
                    newBoardBoard[anchorRow][colIx + 1] = this.fillColor;
                    newBoardBoard[anchorRow][colIx + 2] = this.fillColor;
                    newBoardBoard[anchorRow + 1][colIx + 1] = this.fillColor;
                    let newBoard = new Board(
                        newBoardBoard,
                        newLfr,
                        [anchorRow],
                        [3],
                        0.5,
                        this.numFeatures,
                        false,
                        hasOverlappingFields,
                        colIx,
                        0
                    );
                    if (!newBoard.isTerminalState) {
                        afterStates.push(newBoard);
                    }

                    // T
                    // 1 1 1
                    // 0 1 0

                    newLfr = currentBoard.lfr.slice(0);
                    anchorRow = Math.max(newLfr[colIx] - 1, newLfr[colIx + 1], newLfr[colIx + 2] - 1);
                    newLfr[colIx] = anchorRow + 2;
                    newLfr[colIx + 1] = anchorRow + 2;
                    newLfr[colIx + 2] = anchorRow + 2;
                    newBoardBoard = currentBoard.board.map(arr => arr.slice(0));
                    hasOverlappingFields = false;
                    if (anchorRow + 2 > currentBoard.numRows) {
                        for (let extraRow = 0; extraRow < 4; extraRow += 1) {
                            newBoardBoard.push(Array(this.numColumns).fill(0));
                        }
                        hasOverlappingFields = true;
                    }
                    newBoardBoard[anchorRow][colIx + 1] = this.fillColor;
                    newBoardBoard[anchorRow + 1][colIx] = this.fillColor;
                    newBoardBoard[anchorRow + 1][colIx + 1] = this.fillColor;
                    newBoardBoard[anchorRow + 1][colIx + 2] = this.fillColor;
                    newBoard = new Board(
                        newBoardBoard,
                        newLfr,
                        [anchorRow, anchorRow + 1],
                        [1, 3],
                        0.5,
                        this.numFeatures,
                        false,
                        hasOverlappingFields,
                        colIx,
                        2
                    );
                    if (!newBoard.isTerminalState) {
                        afterStates.push(newBoard);
                    }
                }
            }
        } else if (this.currentTetrominoIndex === 5) {
            // R corner
            // Vertical placements
            for (let colIx = 0; colIx < currentBoard.numColumns - 1; colIx += 1) {
                // Top-right corner is full
                // 1 1 
                // 0 1
                // 0 1
                let newLfr = currentBoard.lfr.slice(0);
                let anchorRow = Math.max(newLfr[colIx] - 2, newLfr[colIx + 1]);
                newLfr[colIx] = anchorRow + 3;
                newLfr[colIx + 1] = anchorRow + 3;
                let newBoardBoard = currentBoard.board.map(arr => arr.slice(0));
                let hasOverlappingFields = false;
                if (anchorRow + 3 > currentBoard.numRows) {
                    for (let extraRow = 0; extraRow < 4; extraRow += 1) {
                        newBoardBoard.push(Array(this.numColumns).fill(0));
                    }
                    hasOverlappingFields = true;
                }
                newBoardBoard[anchorRow][colIx + 1] = this.fillColor;
                newBoardBoard[anchorRow + 1][colIx + 1] = this.fillColor;
                newBoardBoard[anchorRow + 2][colIx] = this.fillColor;
                newBoardBoard[anchorRow + 2][colIx + 1] = this.fillColor;
                let newBoard = new Board(
                    newBoardBoard,
                    newLfr,
                    Array.from({ length: 3 }, (x, i) => i + anchorRow),
                    [1, 1, 2],
                    1,
                    this.numFeatures,
                    false,
                    hasOverlappingFields,
                    colIx,
                    1
                );
                if (!newBoard.isTerminalState) {
                    afterStates.push(newBoard);
                }

                // Bottom-left corner is full
                // 1 0 
                // 1 0
                // 1 1
                newLfr = currentBoard.lfr.slice(0);
                anchorRow = Math.max(newLfr[colIx], newLfr[colIx + 1]);
                newLfr[colIx] = anchorRow + 3;
                newLfr[colIx + 1] = anchorRow + 1;
                newBoardBoard = currentBoard.board.map(arr => arr.slice(0));
                hasOverlappingFields = false;
                if (anchorRow + 3 > currentBoard.numRows) {
                    for (let extraRow = 0; extraRow < 4; extraRow += 1) {
                        newBoardBoard.push(Array(this.numColumns).fill(0));
                    }
                    hasOverlappingFields = true;
                }
                newBoardBoard[anchorRow][colIx] = this.fillColor;
                newBoardBoard[anchorRow][colIx + 1] = this.fillColor;
                newBoardBoard[anchorRow + 1][colIx] = this.fillColor;
                newBoardBoard[anchorRow + 2][colIx] = this.fillColor;
                newBoard = new Board(
                    newBoardBoard,
                    newLfr,
                    [anchorRow],
                    [2],
                    1,
                    this.numFeatures,
                    false,
                    hasOverlappingFields,
                    colIx,
                    3
                );
                if (!newBoard.isTerminalState) {
                    afterStates.push(newBoard);
                }

                // Horizontal placements
                if (colIx < this.numColumns - 2) {
                    // Bottom-right corner
                    // 0 0 1
                    // 1 1 1
                    let newLfr = currentBoard.lfr.slice(0);
                    let anchorRow = Math.max(newLfr[colIx], newLfr[colIx + 1], newLfr[colIx + 2]);
                    newLfr[colIx] = anchorRow + 1;
                    newLfr[colIx + 1] = anchorRow + 1;
                    newLfr[colIx + 2] = anchorRow + 2;
                    let newBoardBoard = currentBoard.board.map(arr => arr.slice(0));
                    let hasOverlappingFields = false;
                    if (anchorRow + 2 > currentBoard.numRows) {
                        for (let extraRow = 0; extraRow < 4; extraRow += 1) {
                            newBoardBoard.push(Array(this.numColumns).fill(0));
                        }
                        hasOverlappingFields = true;
                    }
                    newBoardBoard[anchorRow][colIx] = this.fillColor;
                    newBoardBoard[anchorRow][colIx + 1] = this.fillColor;
                    newBoardBoard[anchorRow][colIx + 2] = this.fillColor;
                    newBoardBoard[anchorRow + 1][colIx + 2] = this.fillColor;
                    let newBoard = new Board(
                        newBoardBoard,
                        newLfr,
                        [anchorRow],
                        [3],
                        0.5,
                        this.numFeatures,
                        false,
                        hasOverlappingFields,
                        colIx,
                        2
                    );
                    if (!newBoard.isTerminalState) {
                        afterStates.push(newBoard);
                    }

                    // Top-left corner
                    // 1 1 1
                    // 1 0 0 
                    newLfr = currentBoard.lfr.slice(0);
                    anchorRow = Math.max(newLfr[colIx], newLfr[colIx + 1] - 1, newLfr[colIx + 2] - 1);
                    newLfr[colIx] = anchorRow + 2;
                    newLfr[colIx + 1] = anchorRow + 2;
                    newLfr[colIx + 2] = anchorRow + 2;
                    newBoardBoard = currentBoard.board.map(arr => arr.slice(0));
                    hasOverlappingFields = false;
                    if (anchorRow + 2 > currentBoard.numRows) {
                        for (let extraRow = 0; extraRow < 4; extraRow += 1) {
                            newBoardBoard.push(Array(this.numColumns).fill(0));
                        }
                        hasOverlappingFields = true;
                    }
                    newBoardBoard[anchorRow][colIx] = this.fillColor;
                    newBoardBoard[anchorRow + 1][colIx] = this.fillColor;
                    newBoardBoard[anchorRow + 1][colIx + 1] = this.fillColor;
                    newBoardBoard[anchorRow + 1][colIx + 2] = this.fillColor;
                    newBoard = new Board(
                        newBoardBoard,
                        newLfr,
                        Array.from({ length: 2 }, (x, i) => i + anchorRow),
                        [1, 3],
                        0.5,
                        this.numFeatures,
                        false,
                        hasOverlappingFields,
                        colIx,
                        0
                    );
                    if (!newBoard.isTerminalState) {
                        afterStates.push(newBoard);
                    }
                }
            }
        } else if (this.currentTetrominoIndex === 6) {
            // L corner
            // Vertical placements
            for (let colIx = 0; colIx < currentBoard.numColumns - 1; colIx += 1) {
                // Top-left corner is full
                // 1 1 
                // 1 0
                // 1 0
                let newLfr = currentBoard.lfr.slice(0);
                let anchorRow = Math.max(newLfr[colIx], newLfr[colIx + 1] - 2);
                newLfr[colIx] = anchorRow + 3;
                newLfr[colIx + 1] = anchorRow + 3;
                let newBoardBoard = currentBoard.board.map(arr => arr.slice(0));
                let hasOverlappingFields = false;
                if (anchorRow + 3 > currentBoard.numRows) {
                    for (let extraRow = 0; extraRow < 4; extraRow += 1) {
                        newBoardBoard.push(Array(this.numColumns).fill(0));
                    }
                    hasOverlappingFields = true;
                }
                newBoardBoard[anchorRow][colIx] = this.fillColor;
                newBoardBoard[anchorRow + 1][colIx] = this.fillColor;
                newBoardBoard[anchorRow + 2][colIx] = this.fillColor;
                newBoardBoard[anchorRow + 2][colIx + 1] = this.fillColor;
                let newBoard = new Board(
                    newBoardBoard,
                    newLfr,
                    Array.from({ length: 3 }, (x, i) => i + anchorRow),
                    [1, 1, 2],
                    1,
                    this.numFeatures,
                    false,
                    hasOverlappingFields,
                    colIx,
                    3
                );
                if (!newBoard.isTerminalState) {
                    afterStates.push(newBoard);
                }

                // Bottom-right corner is full
                // 0 1
                // 0 1
                // 1 1
                newLfr = currentBoard.lfr.slice(0);
                anchorRow = Math.max(newLfr[colIx], newLfr[colIx + 1]);
                newLfr[colIx] = anchorRow + 1;
                newLfr[colIx + 1] = anchorRow + 3;
                newBoardBoard = currentBoard.board.map(arr => arr.slice(0));
                hasOverlappingFields = false;
                if (anchorRow + 3 > currentBoard.numRows) {
                    for (let extraRow = 0; extraRow < 4; extraRow += 1) {
                        newBoardBoard.push(Array(this.numColumns).fill(0));
                    }
                    hasOverlappingFields = true;
                }
                newBoardBoard[anchorRow][colIx] = this.fillColor;
                newBoardBoard[anchorRow][colIx + 1] = this.fillColor;
                newBoardBoard[anchorRow + 1][colIx + 1] = this.fillColor;
                newBoardBoard[anchorRow + 2][colIx + 1] = this.fillColor;
                newBoard = new Board(
                    newBoardBoard,
                    newLfr,
                    [anchorRow],
                    [2],
                    1,
                    this.numFeatures,
                    false,
                    hasOverlappingFields,
                    colIx,
                    1
                );
                if (!newBoard.isTerminalState) {
                    afterStates.push(newBoard);
                }

                // Horizontal placements
                if (colIx < this.numColumns - 2) {
                    // Bottom-left corner
                    // 1 0 0
                    // 1 1 1
                    let newLfr = currentBoard.lfr.slice(0);
                    let anchorRow = Math.max(newLfr[colIx], newLfr[colIx + 1], newLfr[colIx + 2]);
                    newLfr[colIx] = anchorRow + 2;
                    newLfr[colIx + 1] = anchorRow + 1;
                    newLfr[colIx + 2] = anchorRow + 1;
                    let newBoardBoard = currentBoard.board.map(arr => arr.slice(0));
                    let hasOverlappingFields = false;
                    if (anchorRow + 2 > currentBoard.numRows) {
                        for (let extraRow = 0; extraRow < 4; extraRow += 1) {
                            newBoardBoard.push(Array(this.numColumns).fill(0));
                        }
                        hasOverlappingFields = true;
                    }
                    newBoardBoard[anchorRow][colIx] = this.fillColor;
                    newBoardBoard[anchorRow][colIx + 1] = this.fillColor;
                    newBoardBoard[anchorRow][colIx + 2] = this.fillColor;
                    newBoardBoard[anchorRow + 1][colIx] = this.fillColor;
                    let newBoard = new Board(
                        newBoardBoard,
                        newLfr,
                        [anchorRow],
                        [3],
                        0.5,
                        this.numFeatures,
                        false,
                        hasOverlappingFields,
                        colIx,
                        2
                    );
                    if (!newBoard.isTerminalState) {
                        afterStates.push(newBoard);
                    }

                    // Top-right corner
                    // 1 1 1
                    // 0 0 1 
                    newLfr = currentBoard.lfr.slice(0);
                    anchorRow = Math.max(newLfr[colIx] - 1, newLfr[colIx + 1] - 1, newLfr[colIx + 2]);
                    newLfr[colIx] = anchorRow + 2;
                    newLfr[colIx + 1] = anchorRow + 2;
                    newLfr[colIx + 2] = anchorRow + 2;
                    newBoardBoard = currentBoard.board.map(arr => arr.slice(0));
                    hasOverlappingFields = false;
                    if (anchorRow + 2 > currentBoard.numRows) {
                        for (let extraRow = 0; extraRow < 4; extraRow += 1) {
                            newBoardBoard.push(Array(this.numColumns).fill(0));
                        }
                        hasOverlappingFields = true;
                    }
                    newBoardBoard[anchorRow][colIx + 2] = this.fillColor;
                    newBoardBoard[anchorRow + 1][colIx] = this.fillColor;
                    newBoardBoard[anchorRow + 1][colIx + 1] = this.fillColor;
                    newBoardBoard[anchorRow + 1][colIx + 2] = this.fillColor;
                    newBoard = new Board(
                        newBoardBoard,
                        newLfr,
                        Array.from({ length: 2 }, (x, i) => i + anchorRow),
                        [1, 3],
                        0.5,
                        this.numFeatures,
                        false,
                        hasOverlappingFields,
                        colIx,
                        0
                    );
                    if (!newBoard.isTerminalState) {
                        afterStates.push(newBoard);
                    }
                }
            }
        } else {
            throw new Error("'this.currentTetrominoIndex' has to be between 0 and 6.");
        }
        return afterStates;
    }
}
