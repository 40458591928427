import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Sketch from '../../components/sketch';
import sketch from '../../scripts/tetris/sTetrisTest';
import Layout from '../../components/layout';
export const _frontmatter = {
  "title": "Tetris",
  "date": "2019-10-21",
  "slug": "tetris"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <Layout mdxType="Layout">
      <h2>{`blab blab`}</h2>
      <p>{`Even more bla.
`}
        <Sketch sketch={sketch} mdxType="Sketch" /></p>
    </Layout>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      