/* eslint-disable import/no-named-as-default */
/* eslint no-param-reassign: "off", no-use-before-define: "off" */
import Tetris from './tetris';
import Agent from './agent';
import Tetromino from './tetromino';
import Board from './board';

export default function sketch(p) {
    // Libraries
    const cellSize = 10;
    const numColumns = 7;
    const numRows = 12;
    const numASperC = 9;
    const maxNumAS = 34;
    const maxRows = Math.floor(maxNumAS / numASperC);
    const margin = 20;
    const marginWidth = (numASperC + 1) * margin;
    const marginHeight = (maxRows + 2) * margin;
    const boardHeight = numRows * cellSize;
    const boardWidth = numColumns * cellSize;
    const screenWidth = boardWidth * numASperC + marginWidth;
    const screenHeight = boardHeight * (maxRows + 1) + marginHeight;
    let doDraw = true;
    let makeStep = true;
    let fallDown = false;
    // let board = [];
    let tetrisGame;
    let counter = 0;
    const speed = 30;
    let afterStates;
    let currentTetromino;
    let agent;
    let nextState;
    let values;
    let xRotationSlider;
    let yRotationSlider;
    let zRotationSlider;

    let pause = false;

    p.setup = () => {
        p.createCanvas(screenWidth, screenHeight);
        p.frameRate(30);
        p.smooth();
        agent = new Agent();
        tetrisGame = new Tetris(numRows, numColumns);
        currentTetromino = new Tetromino(numColumns);
        currentTetromino.currentTetrominoIndex = tetrisGame.currentTetrominoIndex;
        afterStates = currentTetromino.getAfterStates(tetrisGame.currentBoardState);
        [nextState, values] = agent.chooseAction(afterStates);
        tetrisGame.currentCol = nextState.col;
        tetrisGame.currentRotation = nextState.rotation;
        tetrisGame.updateCurrentTetromino();
        tetrisGame.putTetOnBoard(false);
        // tetrisGame.step();
        p.textSize(8);
        // createSliders();
        // p.noStroke();
    };

    p.draw = () => {
        if (makeStep || fallDown) {
            counter = 0;
            const hasLanded = tetrisGame.step();
            if (hasLanded) {
                fallDown = false;
                if (tetrisGame.gameOver) {
                    tetrisGame.reset();
                }
                currentTetromino.currentTetrominoIndex = tetrisGame.currentTetrominoIndex;
                afterStates = currentTetromino.getAfterStates(tetrisGame.currentBoardState);
                [nextState, values] = agent.chooseAction(afterStates);
                tetrisGame.currentCol = nextState.col;
                tetrisGame.currentRotation = nextState.rotation;
                tetrisGame.updateCurrentTetromino();
                tetrisGame.putTetOnBoard(false);
            }
            doDraw = true;
        }
        // doDraw = true;

        if (doDraw) {
            p.background(220); // 209, 208, 166
            // drawFeatureNames();
            drawPlayingBoard(tetrisGame, (screenWidth - boardWidth) / 2, margin);
            drawAfterStates(afterStates, values);
            drawScore();
            drawFeatureWeights(agent);
            doDraw = false;
        }
        
        // doDraw = false;
        if (!pause) counter += 1;
        makeStep = counter === speed;
    };

    p.keyPressed = () => {
        if (p.keyCode === p.LEFT_ARROW) {
            if (
                tetrisGame.currentCol > 0 &&
                tetrisGame.currentRow >= tetrisGame.lowestFreeRows[tetrisGame.currentCol - 1]
            ) {
                tetrisGame.currentCol -= 1;
                tetrisGame.putTetOnBoard(false);
                doDraw = true;
            }
        } else if (p.keyCode === p.RIGHT_ARROW) {
            if (
                tetrisGame.currentCol + tetrisGame.currentTetromino[0].length < tetrisGame.numColumns &&
                tetrisGame.currentRow >= tetrisGame.lowestFreeRows[tetrisGame.currentCol + tetrisGame.currentTetromino[0].length]
            ) {
                tetrisGame.currentCol += 1;
                tetrisGame.putTetOnBoard(false);
                doDraw = true;
            }
        } else if (p.keyCode === p.UP_ARROW) {
            let tmpRotation = tetrisGame.currentRotation + 1;
            tmpRotation %= tetrisGame.tetNumRotations[tetrisGame.currentTetrominoIndex];
            const tmpTet = tetrisGame.tetrominoShapes[tetrisGame.currentTetrominoIndex][tmpRotation];
            if (tetrisGame.currentCol + tmpTet[0].length <= tetrisGame.numColumns) {
                tetrisGame.currentRotation = tmpRotation;
                tetrisGame.currentTetromino = tmpTet;
                tetrisGame.putTetOnBoard(false);
                doDraw = true;
            }
        } else if (p.keyCode === p.DOWN_ARROW) {
            makeStep = true;
        } else if (p.keyCode === 32) {
            fallDown = true;
        } else if (p.key === 'p') {
            pause = !pause;
        }
    };

    function drawScore() {
        p.textSize(12);
        p.text("Score: " + tetrisGame.score, 10, 10);
    }

    function drawBoard(tetBoard) {
        p.fill(250);
        p.rect(0, 0, boardWidth, boardHeight);
        for (let col = 0; col < numColumns; col += 1) {
            for (let row = 0; row < numRows; row += 1) {
                const f = tetBoard[row][col];
                if (f > 0) {
                    f === 1 ? p.fill(30) : p.fill(173, 39, 40); // 180, 30, 20
                    const x = col * cellSize;
                    const y = boardHeight - (row + 1) * cellSize;
                    p.rect(x, y, cellSize, cellSize);
                }
            }
        }
    }

    function drawPlayingBoard(tet, x, y) {
        p.push();
            p.translate(x, y);
            drawBoard(tet.boardWithTet);
        p.pop();
    }

    function drawFeatureWeights(agent) {
        p.textSize(12);
        p.text("Current feature weights: " + agent.featureWeights, 10, 30);
    }

    function drawAfterStates(as, values) {
        for (let asIx = 0; asIx < as.length; asIx += 1) {
            p.push();
                const rowIx = Math.floor(asIx / numASperC);
                const colIx = asIx % numASperC;
                p.translate(
                    colIx * (boardWidth + margin) + margin,
                    (rowIx + 1) * (boardHeight + margin) + margin
                );
                drawBoard(as[asIx].board);
                p.translate(0, -margin/2);
                p.fill(200, 30, 30);
                p.textSize(9);
                p.text(values[asIx], 0, 0);
                p.fill(80);
                p.textSize(8);
                p.text(as[asIx].features, 20, 0);
            p.pop();
        }
    }

    function drawFeatureNames() {
        p.text(
            'rowsWithHoles, colTransitions, holes, landingHeight, cumulativeWells, rowTransitions, erodedPieceCells, holeDepths', 
            10,
            10
        );
    }

    function createSliders() {
        xRotationSlider = p.createSlider(0, 360, 0);
        xRotationSlider.position(20, 20);
        xRotationSlider.style('width', '80px');

        yRotationSlider = p.createSlider(0, 360, 0);
        yRotationSlider.position(20, 40);
        yRotationSlider.style('width', '80px');

        zRotationSlider = p.createSlider(0, 360, 0);
        zRotationSlider.position(20, 60);
        zRotationSlider.style('width', '80px');
    }
}
