// import Board from './board';

function dotProduct(a, b) {
    let out = 0;
    for (let ix = 0; ix < a.length; ix += 1) {
        out += a[ix] * b[ix];
    }
    return out;
}

export default class Agent {
    constructor() {
        this.featureWeights = [-1, -1, -1, -1, -1, -1, 1, -1];
        this.numFeatures = 8;
    }

    chooseAction(afterStates) {
        // TODO: create matrix, then matrix dot product rather than individual dot products.
        let tmpVal;
        let tmpMax = Number.NEGATIVE_INFINITY;
        let tmpArgMax = 0;
        const numAS = afterStates.length;
        const values = new Array(numAS).fill(0);
        for (let ix = 0; ix < numAS; ix += 1) {
            tmpVal = dotProduct(afterStates[ix].features, this.featureWeights);
            values[ix] = tmpVal;
            if (tmpVal > tmpMax) {
                tmpMax = tmpVal;
                // TODO: implement 'random among argmaxs'
                tmpArgMax = ix;
            }
        }
        return [afterStates[tmpArgMax], values];
    }
}
